import React from 'react';
import * as urlHelper from '../../api/url-helper';

interface Props {
  documentUrl: string;
  panelToShow: number;
  imgClassName?: string;
}

const DocumentPreview = ({ documentUrl, panelToShow, imgClassName }: Props) => {
  if (!documentUrl || documentUrl === '') {
    return null;
  }

  const instructionsUrl = urlHelper.createInstructionsUrl(documentUrl, [panelToShow]);
  const previewUrl = urlHelper.createMcpRenderingUrl(instructionsUrl);

  return (
    <a href={previewUrl} target="_blank" rel="noopener noreferrer">
      <img className={imgClassName} src={previewUrl} alt="" style={{ maxWidth: '450px', maxHeight: '450px' }} title="Click to open in new tab" />
    </a>
  );
};

export default DocumentPreview;

import axios from 'axios';
import { DesignOriginType } from './model/design-origin-type';
import { TextAsset } from './model/text-asset';
import { DesignTransformationInstructions } from './model/design-transformation-instructions';
import { TransformationTarget } from './model/transformation-target';
import { DocumentSourceType } from './model/document-source-type';
import { TemplateLength } from './model/template-length';
import { createToken } from '@vp/dti-tokenizer';
import { TransformationSource } from './model/transformation-source';
import { ImageAsset } from './model/image-asset';
import { v4 as uuidv4 } from 'uuid';
import { StorefrontInstructions } from './model/store-front-instructions';
import { createStorefrontToken } from './design-transformation-realization-api';
import { TextTransformations } from './model/text-transformations';
import { ColorTransformations } from './model/color-transformations';

const headers: { [s: string]: string } = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const documentToDtiSource = (documentUrl: string, textAssets: TextAsset[], imageAssets: ImageAsset[]): TransformationSource => {
  return {
    document: {
      type: DocumentSourceType.Uri,
      value: documentUrl,
      hasFullBleedImage: false
    },
    assets: {
      texts: textAssets,
      images: imageAssets
    }
  };
};

const documentToDtiTarget = (cimdoc: any): TransformationTarget => {
  const panels = cimdoc.document.panels.map((panel: { name: string }) => {
    return {
      name: panel.name,
      designOrigin: {
        type: DesignOriginType.SourcePanelName,
        value: panel.name
      }
    };
  });
  return {
    panels: panels,
    copy: false
  };
};

const toLength = (val: string): TemplateLength => {
  const value = val.slice(0, -2);
  return {
    unit: 'mm',
    value: Number(value)
  };
};

const mapDssToTransformationTarget = (cimdoc: any): TransformationTarget => {
  const panels = cimdoc.document.panels.map((panel: { name: string; width: string; height: string }) => {
    return {
      name: panel.name,
      designOrigin: {
        type: DesignOriginType.TemplateToken,
        value: panel.name
      },
      fullBleedDimensions: {
        width: toLength(panel.width),
        height: toLength(panel.height)
      }
    };
  });
  return {
    panels: panels,
    copy: false
  };
};

const getDocument = async (documentUrl: string): Promise<any> => {
  try {
    const result = await axios.get(documentUrl, { headers });
    return result.data;
  } catch (ex) {
    throw new Error(`get document error ${ex}`);
  }
};

const createGuid = (): string => {
  return uuidv4();
};

export const buildDti = async (documentUrl: string, textAssets: TextAsset[], imageAssets: any): Promise<DesignTransformationInstructions> => {
  //get document by documentUrl
  const document = await getDocument(documentUrl);

  return {
    version: '0.1',
    quality: 1,
    sourceToken: '',
    transformationCorrelationId: createGuid(),
    source: documentToDtiSource(documentUrl, textAssets, imageAssets),
    target: documentToDtiTarget(document)
  };
};

export const buildDtiFromTarget = async (
  documentUrl: string,
  dssUrl: string,
  textAssets: TextAsset[],
  imageAssets: ImageAsset[]
): Promise<DesignTransformationInstructions> => {
  //get document by documentUrl
  const document = await getDocument(dssUrl);

  return {
    version: '0.1',
    quality: 1,
    sourceToken: '',
    transformationCorrelationId: createGuid(),
    source: documentToDtiSource(documentUrl, textAssets, imageAssets),
    target: mapDssToTransformationTarget(document)
  };
};

export const buildStorefrontInstructions = async (
  dssUrl: string,
  textAssets: TextAsset[],
  imageAssets: ImageAsset[],
  textTransformations: TextTransformations,
  colorTransformations: ColorTransformations
): Promise<StorefrontInstructions> => {
  const dssResponse = await getDocument(dssUrl);
  const transformationTarget = mapDssToTransformationTarget(dssResponse);
  return {
    assets: {
      images: imageAssets,
      texts: textAssets
    },
    textTransformations: textTransformations,
    colorTransformations: colorTransformations,
    target: transformationTarget
  };
};

export const buildDtit = (dti: DesignTransformationInstructions): string => {
  const json = JSON.stringify(dti);
  return createToken(json);
};

export const buildDtitStorefront = async (dti: StorefrontInstructions): Promise<string> => {
  const json = JSON.stringify(dti);
  return await createStorefrontToken(json);
};

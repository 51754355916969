import * as PropTypes from 'prop-types';
import React from 'react';

const Tab = () => {
  return <div />;
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default Tab;

import React from 'react';
import { Route, Switch } from 'react-router';
import RealizationPage from './pages/Realization';

const App = () => {

  return (
    <>
      <Switch>
        <Route exact path="/">
          <RealizationPage />
        </Route>
      </Switch>
    </>
  );
};

export default App;

import React, { useState } from 'react';
import TextInput from '../../common/TextInput';

interface Props {
  onUrlChanged: (dssUrl: string, dterUrl: string, templateToken: string) => void;
}

const TargetTemplateInput = ({ onUrlChanged }: Props) => {
  const predefinedTargets = [
    {
      name: 'Solid BG',
      templateToken: 's1e7a073f-6c2e-4c4b-a62f-de098ecdb935..a0bd0bb1-9801-42f7-93bf-fe35649206a0'
    },
    {
      name: 'Square Logo',
      templateToken: 'sbd9bb222-ca38-4e62-9379-3239200589e9..1d50fc4f-a52f-49c3-9e79-31234f193321'
    },
    {
      name: 'Vertical Logo',
      templateToken: 'sa3b55d67-87c5-4bdd-831a-2e55aafbdf08..682ea239-c6e5-4627-a4e9-faabce7fd397'
    },
    {
      name: 'Horizontal Logo',
      templateToken: 'sd9858722-6a6d-4ffa-b55c-b49f22ae9795..f9c58b2a-e04f-4251-a983-6eb421ba478d'
    },
    {
      name: 'Square No Logo',
      templateToken: 'sebac697d-704f-47e3-a73c-8087ae73eba1..1d50fc4f-a52f-49c3-9e79-31234f193321'
    }
  ];

  const [templateToken, setTemplateToken] = useState<string>();

  const updateDesignUrl = (templateToken: string) => {
    const dssUrl = `https://design-specifications.design.vpsvc.com/designDocuments/templates/${templateToken}?colorMode=print&finishType=none&culture=en-us`;
    const dterUrl = `https://prod.templaterealization.content.cimpress.io/api/v2/templates/${templateToken}/cultures/en-us/editDocument`;

    setTemplateToken(templateToken);

    if (onUrlChanged) {
      onUrlChanged(dssUrl, dterUrl, templateToken);
    }
  };

  return (
    <form style={{ flexDirection: 'column', maxWidth: '800px' }}>
      <TextInput
        inline
        style={{ marginRight: '8px', marginBottom: '8px' }}
        value={templateToken}
        onChange={e => {
          updateDesignUrl(e.target.value);
        }}
        placeholder="https://"
      />

      <div style={{ display: 'flex' }}>
        {predefinedTargets.map(example => (
          <button key={example.name} type="button" className="alt" onClick={() => updateDesignUrl(example.templateToken)}>
            {example.name}
          </button>
        ))}
      </div>
    </form>
  );
};

export default TargetTemplateInput;

import React, { useState, FormEvent } from 'react';
import { CultureCode } from '../../redux/models/CultureCode';
import DtiTokenEditor from './DtiTokenEditor';
import { TransformationInstructions } from './TransformationInstructions';
import { RealizationServiceResult } from '../../api/DesignTransformationRealizationService/realization-service-result';
import RealizationOutput from './RealizationOutput';
import * as realizationApi from '../../api/DesignTransformationRealizationService/design-transformation-realization-api';
import DtrEnvironmentSwitch from './DtrEnvironmentSwitch';
import { EnvironmentType } from '../../api/DesignTransformationRealizationService/model/dtr-environment-type';
import { SlidingSwitch } from '../common';

interface Props {
  selectedCulture: CultureCode;
  setSelectedCultureCode: (selectedCultureCode: CultureCode) => void;
}

const PreviewInstructions = ({ selectedCulture, setSelectedCultureCode }: Props) => {
  const defaultRealizationResult: RealizationServiceResult = {
    realizedDocumentUrl: '',
    document: undefined
  };
  const [loading, setLoading] = useState(false);
  const [realizationResult, setRealizationResult] = useState(defaultRealizationResult);
  const [dtrEnvironment, setDtrEnvironment] = useState<EnvironmentType>('PROD');
  const [fillEmptyPlaceholders, setFillEmptyPlaceholders] = useState<boolean>(false);
  let giveMeToken: () => Promise<TransformationInstructions>;

  const submitRealization = async (encodedInstructions: string) => {
    setLoading(true);

    try {
      const result = await realizationApi.realize(encodedInstructions, {
        culture: selectedCulture.fullCultureCode,
        environment: dtrEnvironment,
        fillEmptyPlaceholders: fillEmptyPlaceholders
      });
      setRealizationResult(result);
    } catch (e) {
      alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const newInstructions = await giveMeToken();
      await submitRealization(newInstructions.encodedValue);
    } catch (e) {
      alert(e.message);
      return;
    }
  };

  const tokenGetterMemoizer = function (getter: () => Promise<TransformationInstructions>) {
    giveMeToken = getter;
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h3 className="underline" style={{ marginBottom: 8 }}>
            <span className="highlight">Design</span> Transformation Instructions
          </h3>

          <div style={{ flex: 1 }}></div>
          <DtrEnvironmentSwitch environment={dtrEnvironment} setEnvironment={setDtrEnvironment} />
          <SlidingSwitch
            style={{ backgroundColor: 'white', zIndex: 1000 }}
            name="fill-empty-placeholders-switch"
            leftLabel="Don't"
            rightLabel="Fill Empty Text Elements"
            checked={fillEmptyPlaceholders}
            onChange={e => setFillEmptyPlaceholders(e.target.checked)}
          />
        </div>
        <div style={{ width: 'fit-content', position: 'relative' }}>
          <DtiTokenEditor getToken={tokenGetterMemoizer} dtrEnvironment={dtrEnvironment} />
        </div>
        <div style={{ display: 'flex', marginTop: '.7em' }}>
          <input type="text" value={selectedCulture.fullCultureCode} onChange={e => setSelectedCultureCode({
            ...selectedCulture,
            fullCultureCode: e.target.value
          })}></input>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '.7em' }}>
          <button disabled={loading}>{loading ? 'Processing...' : 'Realize'}</button>
        </div>
      </form>
      <RealizationOutput realizationResult={realizationResult} />
    </>
  );
};

export default PreviewInstructions;

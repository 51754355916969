import React, { useState } from 'react';
import classes from './PreviewUrlPage.module.scss';
import DocumentUrlComponent from '../document-url/DocumentUrlComponent';
import PreviewUrlAnalysis from './PreviewUrlAnalysis';
import { CultureCode } from '../../redux/models/CultureCode';

interface Props {
  cultureCode: CultureCode;
}

const PreviewUrlPage = ({ cultureCode }: Props) => {
  const [documentUrl, setDocumentUrl] = useState('');

  return (
    <div className={classes.previewContainer}>
      <h3 className="underline" style={{ marginBottom: 8 }}>
        <span className="highlight">Set</span> Preview URL for analysis
      </h3>
      <DocumentUrlComponent onDocumentUrlChanged={value => setDocumentUrl(value)} />

      <PreviewUrlAnalysis previewUrl={documentUrl} cultureCode={cultureCode} />
    </div>
  );
};

export default PreviewUrlPage;

import React, { ChangeEvent } from 'react';
import { ColorTransformations } from '../../../api/DesignTransformationRealizationService/model/color-transformations';
import { HuePicker, Color, ColorResult } from 'react-color';
import { useState } from 'react';
import { getTitleTextColor, TemplateColorScheme } from '../../../api/DominantColors';
import colorconvert from 'color-convert';
interface Props {
  onValueChanged: (value: ColorTransformations) => void;
  colorScheme?: TemplateColorScheme;
}
const emptyTransformation: ColorTransformations = {
  background: {
    color: '',
    backgroundType: 'Image',
    hueMultiplier: 1,
    hueOffset: 0,
    lightnessMultiplier: 1,
    lightnessOffset: 0,
    saturationMultiplier: 1,
    saturationOffset: 0
  },
  primaryText: {
    color: ''
  },
  secondaryText: {
    color: ''
  }
};

const ColorTransformationsPicker = ({ onValueChanged, colorScheme }: Props) => {
  const colorSets = [
    {
      primaryText: 'rgb(190, 255, 242)',
      secondaryText: 'rgb(215, 249, 80)',
      background: 'rgb(253, 89, 92)'
    },
    {
      primaryText: 'rgb(89, 253, 168)',
      secondaryText: 'rgb(89, 174, 253)',
      background: 'rgb(253, 145, 85)'
    },
    {
      primaryText: 'rgb(97, 16, 123)',
      secondaryText: 'rgb(89, 174, 253)',
      background: 'rgb(254, 221, 100)'
    },
    {
      primaryText: 'rgb(130, 216, 95)',
      secondaryText: 'rgb(89, 174, 253)',
      background: 'rgb(89, 253, 168)'
    },
    {
      primaryText: 'rgb(165, 180, 25)',
      secondaryText: 'rgb(89, 174, 253)',
      background: 'rgb(65, 84, 252)'
    },
    {
      primaryText: 'rgb(89, 253, 168)',
      secondaryText: 'rgb(89, 174, 253)',
      background: 'rgb(147, 84, 251)'
    }
  ];

  const initialColor: Color = '#fff';
  const [background, setBackground] = useState(initialColor);
  const [colorTransformations, setColorTransformations] = useState<ColorTransformations>(emptyTransformation);

  const hexTpRgb = (hex: string): string => {
    const rgb = colorconvert.hex.rgb(hex);
    return rgb ? `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})` : '';
  };

  const [useRecommendedText, setUseRecommendedText] = useState(false);

  const handleChangeComplete = (color: ColorResult) => {
    setBackground(color.hex);
    const destinationH = color.hsl.h;
    const sourceH = colorScheme && colorScheme.hsl ? colorScheme.hsl[0] * 360 : 0;
    const hueOffset = 360 - sourceH + destinationH;
    const satOffset = colorScheme && colorScheme.hsl ? color.hsl.s - colorScheme.hsl[1] : 0;
    const lOffset = colorScheme && colorScheme.hsl ? color.hsl.l - colorScheme.hsl[2] : 0;

    const newTransformatio: ColorTransformations = {
      ...colorTransformations,
      background: {
        color: '',
        backgroundType: 'Image',
        hueMultiplier: 1,
        hueOffset: hueOffset,
        lightnessMultiplier: 1,
        lightnessOffset: lOffset,
        saturationMultiplier: 1,
        saturationOffset: satOffset
      }
    };
    setColorTransformations(newTransformatio);

    onValueChanged(newTransformatio ? newTransformatio : emptyTransformation);
  };

  const handleUseRecommendedText = (use: boolean) => {
    setUseRecommendedText(use);

    const textColorHex = getTitleTextColor(colorconvert.hex.rgb(background));
    const textColor = hexTpRgb(textColorHex);

    const newTransformatio: ColorTransformations = {
      ...colorTransformations,
      primaryText: {
        color: use ? textColor : ''
      },
      secondaryText: {
        color: use ? textColor : ''
      }
    };

    setColorTransformations(newTransformatio);
    onValueChanged(newTransformatio ? newTransformatio : emptyTransformation);
  };

  const predefinedColors: ColorTransformations[] = colorSets.map(set => {
    return {
      primaryText: {
        color: set.primaryText
      },
      secondaryText: {
        color: set.secondaryText
      },
      background: {
        backgroundType: 'Solid',
        hueMultiplier: 1,
        hueOffset: 0,
        lightnessMultiplier: 1,
        lightnessOffset: 0,
        saturationMultiplier: 1,
        saturationOffset: 0,
        color: set.background
      }
    };
  });

  return (
    <div>
      <div>
        image color
        <HuePicker color={background} onChangeComplete={handleChangeComplete} />
        <input
          type="checkbox"
          name="useText"
          id="useTextId"
          checked={useRecommendedText}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleUseRecommendedText(event.target.checked)}
        />
        use UseRecommendedText
      </div>
      solid color
      {predefinedColors.map(example => (
        <button
          style={{
            backgroundColor: example.background.color,
            border: 'none',
            marginRight: '10px',
            marginTop: '10px',
            paddingLeft: '16px',
            paddingRight: '16px',
            borderRadius: '4px'
          }}
          key={example.background.color}
          type="button"
          className="alt"
          onClick={() => onValueChanged(example)}
        ></button>
      ))}
    </div>
  );
};

export default ColorTransformationsPicker;

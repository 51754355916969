import React, { useState } from 'react';
import { SlidingSwitch } from '../common';
import ReactJson from 'react-json-view';
import './DocumentViewer.css';

require('brace/mode/json');
require('brace/theme/textmate');

interface Props {
  document: {};
}

const DocumentViewer = ({ document }: Props) => {
  const [visualizeOutputJson, setVisualizeOutputJson] = useState(false);
  const stringifiedDocument = JSON.stringify(document, null, 2) || '';

  return (
    <>
      <SlidingSwitch
        name="output-document-format"
        style={{ marginBottom: '.65em' }}
        leftLabel="Show as plain JSON"
        checked={visualizeOutputJson}
        onChange={event => setVisualizeOutputJson(event.target.checked)}
      />

      <pre className={visualizeOutputJson === true ? '' : 'hidden'}>{stringifiedDocument}</pre>

      <div className={visualizeOutputJson === false ? '' : 'hidden'}>
        <ReactJson src={document} collapsed={false} />
      </div>
    </>
  );
};

export default DocumentViewer;

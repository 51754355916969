import React, { useEffect, useState } from 'react';
import { TextInput } from '.';

interface Props {
  onSave: (value: string) => void;
  initialValue?: string;
  errorMsg?: string;
}

const ResettableInput = ({ onSave, initialValue, errorMsg}: Props) => {
  const [workingValue, setWorkingValue] = useState(initialValue || '');

  useEffect(() => {
    setWorkingValue(initialValue || '');
  }, [initialValue]);

  return (
    <form>
        <>
          <TextInput inline value={workingValue} onChange={e => onSave(e.target.value)} placeholder="https://" error={errorMsg} />
        </>
    </form>
  );
};

export default ResettableInput;

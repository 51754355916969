import React, { useState, ChangeEvent } from 'react';
import { SlidingSwitch } from '../common';
import { EnvironmentType } from '../../api/DesignTransformationRealizationService/model/dtr-environment-type';

interface Props {
  environment: EnvironmentType;
  setEnvironment: (environment: EnvironmentType) => void;
}

const DtrEnvironmentSwitch = ({ environment, setEnvironment }: Props) => {
  const [checked, setChecked] = useState(environment === 'PROD' ? true : false);

  const handleEnvironmentChange = function(event: ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
    setEnvironment(event.target.checked ? 'PROD' : 'DEV');
  };

  return (
    <SlidingSwitch
      style={{ backgroundColor: 'white', zIndex: 1000 }}
      name="environment-switch"
      leftLabel="DEV"
      rightLabel="PROD"
      checked={checked}
      onChange={handleEnvironmentChange}
    />
  );
};

export default DtrEnvironmentSwitch;

import axios from 'axios';
import { DocumentSource } from '../../api/DesignTransformationRealizationService/model/document-source';
import * as dtrApi from '../../api/DesignTransformationRealizationService/design-transformation-realization-api';
import { DesignTransformationInstructions } from '../../api/DesignTransformationRealizationService/model/design-transformation-instructions';

export interface ParsedDtiToken {
  encodedValue: string;
  object: DesignTransformationInstructions;
  sourceDocumentUrl: string;
  sourcePanelCount: number;
  targetPanelCount: number;
}

const extractDocumentUrl = (document: DocumentSource, authToken: string | undefined, fullCultureCode: string) => {
  if (document.type.localeCompare('Uri', undefined, { sensitivity: 'accent' }) === 0) {
    return document.value as string;
  }

  const payload = typeof document.value === 'string' ? (document.value as string) : JSON.stringify(document.value);
  return dtrApi.getRealizationUrl(payload, { authToken: authToken, culture: fullCultureCode });
};

const extractSourcePanelCount = async (document: DocumentSource) => {
  if (document.type.localeCompare('Uri', undefined, { sensitivity: 'accent' }) === 0) {
    const documentResponse = await axios.get(document.value as string);
    const documentJson = documentResponse.data as any;

    return documentJson.document.panels.length as number;
  }

  const payload =
    typeof document.value === 'string'
      ? (JSON.parse(document.value) as DesignTransformationInstructions)
      : (document.value as DesignTransformationInstructions);

  return payload.target.panels.length;
};

export const parseDtiToken = async (token: string, authToken: string | undefined, fullCultureCode: string) => {
  const parsedToken = await dtrApi.parseToken(token, {
    authToken: authToken,
    culture: fullCultureCode
  });
  const instructions = JSON.parse(parsedToken) as DesignTransformationInstructions;
  const sourceDocumentUrl = extractDocumentUrl(instructions.source.document, authToken, fullCultureCode);

  const sourceDocumentPanelCount = await extractSourcePanelCount(instructions.source.document);
  const targetPanelCount = instructions.target.panels.length;

  return {
    encodedValue: token,
    object: instructions,
    sourceDocumentUrl: sourceDocumentUrl,
    sourcePanelCount: sourceDocumentPanelCount,
    targetPanelCount: targetPanelCount
  };
};

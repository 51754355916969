export const prodRealizationServiceHost = 'https://prod.realization.artworkgeneration.cimpress.io';
export const devRealizationServiceHost = 'https://dev.realization.artworkgeneration.cimpress.io';

export const udsInstructionsPath = 'https://uds.documents.cimpress.io/v3/instructions:preview';
export const mcpRenderingHostPath = 'https://rendering.documents.cimpress.io/v1/vp/preview?width=1000';
export const vortex3DRenderingHostPath = 'https://tools.documents.cimpress.io/3d/index.html';

export const productCatalogServiceHost = 'https://catalog.products.vpsvc.com';

export const tssHost = 'https://targetspecifications.artworkgeneration.cimpress.io';
export const dtcHost = 'https://catalog.artworkgeneration.cimpress.io';

import { PanelSpecification } from './model/panel-specification';

export const createDssBody = (panelSpecifications: PanelSpecification[]) => {
  const requestBody = {
    panels: panelSpecifications.map(p => {
      return {
        panelSource: {
          source: 'TEMPLATE_TOKEN',
          data: p.designOrigin ? p.designOrigin.value : null
        },
        panelTarget: {
          name: p.name,
          colorType: p.isBlank ? 'notApplicable' : 'color',
          widthCm: p.fullBleedDimensions ? p.fullBleedDimensions.width.value : 0,
          heightCm: p.fullBleedDimensions ? p.fullBleedDimensions.height.value : 0
        }
      };
    })
  };

  return requestBody;
};

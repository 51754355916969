import React, { ChangeEvent } from 'react';
import { CultureCode } from '../../redux/models/CultureCode';
import { SelectInput } from '../common';

interface Props {
  setSelectedCultureCode: (selectedCultureCode: CultureCode) => void;
  selectedCultureCode: CultureCode;
}

const CultureCodesComponent = (props: Props) => {
  const { setSelectedCultureCode, selectedCultureCode } = props;
  const cultureCodes = [
    { countryName: 'English (United States)', shortCultureCode: 'en-US', fullCultureCode: 'en-US' },
    { countryName: 'English (Ireland)', shortCultureCode: 'en-IE', fullCultureCode: 'en-IE' },
    { countryName: 'Portuguese (Portugal)', shortCultureCode: 'pt-PT', fullCultureCode: 'pt-PT' }
  ];
  const defaultCultureCode = { countryName: 'English (United States)', shortCultureCode: 'en-US', fullCultureCode: 'en-US' };

  const cultureCodeToOption = (cultureCode: CultureCode) => {
    return { text: cultureCode.countryName, value: cultureCode.fullCultureCode };
  };

  const handleSelection = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedCultureCode = cultureCodes.find(cc => cc.fullCultureCode === event.target.value);
    if (!selectedCultureCode) return;

    setSelectedCultureCode(selectedCultureCode);
  };

  const options = cultureCodes.map(cultureCodeToOption);

  const currentCultureCode = selectedCultureCode ? selectedCultureCode : defaultCultureCode;
  const defaultOption = cultureCodeToOption(currentCultureCode).value;
  setSelectedCultureCode(currentCultureCode);

  return (
    <form>
      <SelectInput name="cultureCodesSelect" value={defaultOption} options={options} onChange={handleSelection} />
    </form>
  );
};

export default CultureCodesComponent;

import React from 'react';
import TargetTemplateInput from './TargetTemplateInput';

export interface TargetTemplateInfo {
  templateToken: string;
  dssUrl: string;
  dterUrl: string;
}

interface Props {
  targetTemplateInfo: TargetTemplateInfo;
  setTargetTemplateInfo: (targetTemplateInfo: TargetTemplateInfo) => void;
}

const TargetTemplateToken = ({ targetTemplateInfo, setTargetTemplateInfo }: Props) => {
  return (
    <>
      <h4 className="section-header underline">
        <span className="highlight">Target</span> Template Token
      </h4>

      <div>
        <TargetTemplateInput
          onUrlChanged={(dssUrl: string, dterUrl: string, templateToken: string) => {
            setTargetTemplateInfo({ templateToken, dssUrl, dterUrl });
          }}
        />
        {targetTemplateInfo.dterUrl && (
          <div>
            <p>
              <a href={targetTemplateInfo.dssUrl} target="_blank" rel="noopener noreferrer" title={targetTemplateInfo.dssUrl}>
                DSS Url
              </a>
              <span> | </span>
              <a href={targetTemplateInfo.dterUrl} target="_blank" rel="noopener noreferrer" title={targetTemplateInfo.dterUrl}>
                DTeR Url
              </a>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default TargetTemplateToken;

import React, { useState } from 'react';
import axios from 'axios';
import ResettableInput from '../common/ResettableInput';

interface Props {
  onDocumentUrlChanged: (value: string) => void;
  initialValue?: string;
}

const DocumentUrlComponent = ({ onDocumentUrlChanged, initialValue }: Props) => {
  const [documentUrl, setDocumentUrl] = useState(initialValue || '');
  const [errMsg, setErrMsg] = useState('');

  const validateUrl = (url: string) => {
    url &&
      axios
        .get(url)
        .then(response => {
          console.log(response);
          
          if (response.status === 200) {
            setErrMsg('');   
            setDocumentUrl(url);
            onDocumentUrlChanged(url);
          } else if (response.status === 302) {
            setErrMsg('');   
            const location = response.headers['location']
            console.log(location);
            
            validateUrl(location);
          }
          else {
            setErrMsg(`Response status ${response.status} is not supported, see console for full response`);   
            console.error(response);            
          }
        })
        .catch(() => {
          setErrMsg('Document URL is invalid');   
          setDocumentUrl(url);
          onDocumentUrlChanged(url);
        });
  };

  return (
    <>
      <ResettableInput onSave={validateUrl} initialValue={documentUrl} errorMsg={errMsg} />
    </>
  );
};

export default DocumentUrlComponent;

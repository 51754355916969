import React, { useEffect, useState } from 'react';
import * as api from '../../api/DesignTransformationRealizationService/design-transformation-realization-api';
import * as dssHelper from '../../api/DesignTransformationRealizationService/dss-helper';
import * as DocumentSourceType from '../../api/DesignTransformationRealizationService/model/document-source-type';

import classes from './PreviewUrlPage.module.scss';
import { CultureCode } from '../../redux/models/CultureCode';
import { ReadonlyText } from '../common';
import { DesignTransformationInstructions } from '../../api/DesignTransformationRealizationService/model/design-transformation-instructions';

interface Props {
  previewUrl?: string;
  cultureCode: CultureCode;
}

interface DssRequest {
  body: {};
  url: string;
}

interface PreviewParsedUrl {
  original: string;
  previewInstructionsUrl?: string;
  documentUrl?: string;
  dtit?: string;
  dti?: DesignTransformationInstructions;
  dssRequest?: DssRequest;
}

const PreviewUrlAnalysis = ({ previewUrl, cultureCode }: Props) => {
  const defaultParsedUrl: PreviewParsedUrl = { original: '' };
  const [parsedUrl, setParsedUrl] = useState(defaultParsedUrl);

  const generateDtiWithDocumentPayload = async (dti: DesignTransformationInstructions | undefined) => {
    if (dti) {
      const newDti = JSON.parse(JSON.stringify(dti));
      const documentPayload = await api.loadSourceDocumentPayload(dti);

      newDti.source.document = {
        type: DocumentSourceType.DocumentSourceType.Payload,
        value: documentPayload
      };
    }
  };

  useEffect(() => {
    const parseDtit = async (previewParsedUrl: PreviewParsedUrl): Promise<PreviewParsedUrl> => {
      try {
        const dtit = previewParsedUrl.dtit || '';
        const parseTokenResult = await api.parseToken(dtit, { culture: cultureCode.shortCultureCode, authToken: undefined });
        previewParsedUrl.dti = JSON.parse(parseTokenResult) as DesignTransformationInstructions;

        previewParsedUrl.dssRequest = {
          url: `https://design-specifications.design.vpsvc.com/designDocuments/documentSpecification?culture=${cultureCode.shortCultureCode}`,
          body: dssHelper.createDssBody(previewParsedUrl.dti.target.panels)
        };
      } catch (e) {
        alert(e.message);
      }

      return previewParsedUrl;
    };

    const parsePreviewUrl = async (url: string): Promise<PreviewParsedUrl> => {
      let result: PreviewParsedUrl = { original: url };

      try {
        const baseUrl = new URL(url);
        const decodedPreviewInstructions = baseUrl.searchParams.get('instructions_uri') || baseUrl.searchParams.get('previewInstructionsUri');

        result.previewInstructionsUrl = decodedPreviewInstructions || '';

        const isLinkToUds = result.previewInstructionsUrl.startsWith('https://uds.documents.cimpress.io') || 
              result.previewInstructionsUrl.startsWith('https://instructions.documents.cimpress.io') ;

        if (isLinkToUds) {
          const udsUrl = new URL(result.previewInstructionsUrl);
          const decodedDocumentUrl = udsUrl.searchParams.get('documentUri');
          result.documentUrl = decodedDocumentUrl || '';

          const isLinkToRealization = result.documentUrl.includes('realizeToken');

          if (isLinkToRealization) {
            const realizationUrl = new URL(result.documentUrl);
            result.dtit = realizationUrl.searchParams.get('token') || '';
            result = await parseDtit(result);
          }
        }
      } catch (error) {
        console.log(error);
      }

      return result;
    };

    async function parse(previewUrl: string) {
      const parsed = await parsePreviewUrl(previewUrl);
      setParsedUrl(parsed);
    }

    if (previewUrl) {
      parse(previewUrl);
    }
  }, [previewUrl, cultureCode.shortCultureCode]);

  return parsedUrl && parsedUrl.original ? (
    <form style={{ flexDirection: 'column' }}>
      <h4>Preview instructions</h4>
      <ReadonlyText value={parsedUrl.previewInstructionsUrl} />

      <h4>Document Url</h4>
      <ReadonlyText value={parsedUrl.documentUrl} />

      <h4>Design Transformation Instructions token (DTIT)</h4>
      <ReadonlyText value={parsedUrl.dtit} />

      <h4>Design Transformation Instructions</h4>
      <h5>Source document</h5>
      <p>
        {parsedUrl.dti && parsedUrl.dti.source.document && parsedUrl.dti.source.document.type === DocumentSourceType.DocumentSourceType.Uri && (
          <span>{parsedUrl.dti.source.document.value as string}</span>
        )}
        &nbsp;
        <button className="link" type="button" onClick={() => generateDtiWithDocumentPayload(parsedUrl.dti)}>
          Change
        </button>
      </p>

      <h5>Panels</h5>
      {parsedUrl.dti &&
        parsedUrl.dti.target.panels.map(p => (
          <p key={p.name} style={{ margin: '.2em 0' }}>
            {p.name}:{' '}
            {p.designOrigin && (
              <span>
                https://prod.templaterealization.content.cimpress.io/api/v2/templates/{p.designOrigin.value}/cultures/{cultureCode.shortCultureCode}
                /editDocument?finishType=none
              </span>
            )}
          </p>
        ))}

      <h5>DSS request</h5>
      {parsedUrl.dssRequest && (
        <>
          <p>{parsedUrl.dssRequest.url}</p>
          <pre>{JSON.stringify(parsedUrl.dssRequest.body, null, 2)}</pre>
        </>
      )}
    </form>
  ) : (
    <div className={classes.empty}>Preview URL not set...</div>
  );
};

export default PreviewUrlAnalysis;

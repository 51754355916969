import React, { useState } from 'react';
import DocumentPreview from './DocumentPreview';
import * as dtrApi from '../../api/DesignTransformationRealizationService/design-transformation-realization-api';
import ResettableInput from '../common/ResettableInput';
import { CultureCode } from '../../redux/models/CultureCode';
import { parseDtiToken, ParsedDtiToken } from './DtrDocumentAnalysis';
import styles from './DocumentPreviewComparer.module.scss';

interface Props {
  selectedCulture: CultureCode;
}

const DocumentPreviewComparer = ({ selectedCulture }: Props) => {
  const [inputTokens, setInputTokens] = useState([] as string[]);
  const [parsedTokens, setParsedTokens] = useState([] as ParsedDtiToken[]);

  const onAddToken = () => {
    inputTokens.push('');
    setInputTokens(inputTokens.slice());
  };

  const handleTokenSaved = async (index: number, token: string) => {
    inputTokens[index] = token;
    setInputTokens(inputTokens.slice());

    try {
      const parsedToken = await parseDtiToken(token, undefined, selectedCulture.fullCultureCode);
      parsedTokens.push(parsedToken);

      setParsedTokens(parsedTokens.slice());
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <>
      <ul className={styles.tokenlist}>
        {inputTokens.map((token, index) => {
          return (
            <>
              <li key={index}>
                <ResettableInput initialValue={token} onSave={t => handleTokenSaved(index, t)} />
              </li>
            </>
          );
        })}
      </ul>

      <button type="button" onClick={onAddToken}>
        Add another DTI
      </button>

      <table>
        <thead>
          <tr>
            <th>Token</th>
            <th>Original Document</th>
            <th>DEV</th>
            <th>PROD</th>
          </tr>
        </thead>
        <tbody>
          {parsedTokens.map(token => {
            return (
              <tr key={token.encodedValue}>
                <td>
                  <span className={styles.breakwords}>{token.encodedValue}</span>
                </td>
                <td className={styles.textaligncenter}>
                  {Array(token.sourcePanelCount)
                    .fill(0)
                    .map((_, index) => {
                      return <DocumentPreview key={index} imgClassName={'bordered'} documentUrl={token.sourceDocumentUrl} panelToShow={index + 1} />;
                    })}
                </td>
                <td className={styles.textaligncenter}>
                  {Array(token.targetPanelCount)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <DocumentPreview
                          key={index}
                          imgClassName={'bordered'}
                          documentUrl={dtrApi.getRealizationUrl(token.encodedValue, { environment: 'DEV' })}
                          panelToShow={index + 1}
                        />
                      );
                    })}
                </td>
                <td className={styles.textaligncenter}>
                  {Array(token.targetPanelCount)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <DocumentPreview
                          key={index}
                          imgClassName={'bordered'}
                          documentUrl={dtrApi.getRealizationUrl(token.encodedValue, { environment: 'PROD' })}
                          panelToShow={index + 1}
                        />
                      );
                    })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default DocumentPreviewComparer;

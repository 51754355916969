import React, { useEffect, useState } from 'react';
import { TextAsset } from '../../../api/DesignTransformationRealizationService/model/text-asset';
import JsonEditor from '../../common/JsonEditor';

interface Props {
  onAssetsChanged: (textAssets: TextAsset[]) => void;
  textAssets: TextAsset[];
}

const TextAssets = ({ onAssetsChanged, textAssets }: Props) => {
  const [textAssetsInternal, setTextAssetsInternal] = useState(JSON.stringify(textAssets, undefined, 2));

  useEffect(() => {
    const updateAssets = (newAssets: string) => {
      try {
        const newAssetsJson = JSON.parse(newAssets);
        onAssetsChanged(newAssetsJson);
      } catch (error) {
        console.error(error);
      }
    };

    updateAssets(textAssetsInternal);
    // eslint-disable-next-line
  }, [textAssetsInternal]);

  return (
    <>
      <h4 className="section-header underline">
        <span className="highlight">Text</span> Assets
      </h4>

      <JsonEditor value={textAssetsInternal} onValueChanged={value => setTextAssetsInternal(value)} placeholder={'// text asset instructions'} />
    </>
  );
};

export default TextAssets;

import React, { useState, useEffect } from 'react';
import { CultureCode } from '../../../redux/models/CultureCode';

import { buildDtitStorefront, buildStorefrontInstructions } from '../../../api/DesignTransformationRealizationService/dti-builder';
import * as dtrApi from '../../../api/DesignTransformationRealizationService/design-transformation-realization-api';
import DocumentPreview from '../DocumentPreview';
import { TextAsset } from '../../../api/DesignTransformationRealizationService/model/text-asset';
import { ImageAsset } from '../../../api/DesignTransformationRealizationService/model/image-asset';
import axios from 'axios';
import { TextTransformations } from '../../../api/DesignTransformationRealizationService/model/text-transformations';
import TargetTemplateToken, { TargetTemplateInfo } from './TargetTemplateToken';
import SimpleTransformationEdit from './SimpleTransformationEdit';
import { ColorTransformations } from '../../../api/DesignTransformationRealizationService/model/color-transformations';
import { TextTransformation } from '../../../api/DesignTransformationRealizationService/model/text-transformation';
import TextAssets from './TextAssets';
import LogoUrl from './LogoUrl';
import * as urlHelper from '../../../api/url-helper';
import { GetColorPalete, TemplateColorScheme } from '../../../api/DominantColors';
import ColorTransformationEdit from './ColorTransformationEdit ';

interface Props {
  selectedCulture: CultureCode;
}

const StoreFront = ({ selectedCulture }: Props) => {
  const textAssetsDefault: TextAsset[] = [
    {
      purpose: 'companymessage',
      content: 'Everything for you business',
      hasPriority: true
    },
    {
      purpose: 'web',
      content: 'www.vistaprint.com',
      hasPriority: true
    },
    {
      purpose: 'firstName',
      content: 'Mr. Krtecek',
      hasPriority: true
    },
    {
      purpose: 'lastname',
      content: 'Vochomurka',
      hasPriority: true
    },
    {
      purpose: 'companyname',
      content: "Ivak's Barber Shop",
      hasPriority: true
    },
    {
      purpose: 'businesstype',
      content: 'Hair In Shape',
      hasPriority: true
    },
    {
      purpose: 'phone',
      content: '+420 123 456 789',
      hasPriority: true
    }
  ];

  const [textAssets, setTextAssets] = useState(textAssetsDefault);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoAssets, setLogoAssets] = useState<ImageAsset[]>([]);

  const [targetTemplateInfo, setTargetTemplateInfo] = useState<TargetTemplateInfo>({
    templateToken: '',
    dssUrl: '',
    dterUrl: ''
  });

  const emptyTextTransformation: TextTransformation = {
    fontFamily: '',
    fontSize: '',
    fontStyle: '',
    case: 'KeepCurrent'
  };
  const [textTransformations, setTextTransformations] = useState<TextTransformations>({
    primary: emptyTextTransformation,
    secondary: emptyTextTransformation
  });

  const [colorTransformations, setColorTransformations] = useState<ColorTransformations>({
    primaryText: {
      color: ''
    },
    secondaryText: {
      color: ''
    },
    background: {
      backgroundType: 'Solid',
      color: '',
      hueMultiplier: 1,
      hueOffset: 0,
      lightnessMultiplier: 1,
      lightnessOffset: 0,
      saturationMultiplier: 1,
      saturationOffset: 0
    }
  });

  const [dtrUrl, setDtrUrl] = useState('');

  useEffect(() => {
    const createLogoAssets = async (): Promise<ImageAsset[]> => {
      if (logoUrl) {
        const imageMetadata = await axios.get(logoUrl + '&variant=metadata');
        const documimageMetadataEx = imageMetadata.data as any;

        return [
          {
            previewUrl: logoUrl,
            printUrl: logoUrl,
            originalSourceUrl: logoUrl,
            purposes: ['Logo'],
            hasPriority: true,
            heightInPixels: documimageMetadataEx.printPixelHeight,
            widthInPixels: documimageMetadataEx.printPixelWidth
          }
        ];
      }

      return [];
    };

    createLogoAssets().then(logoAssets => {
      setLogoAssets(logoAssets);
    });
  }, [logoUrl]);

  const [previewColorScheme, setPreviewColorScheme] = useState<TemplateColorScheme>();

  useEffect(() => {
    const createColorData = async (): Promise<TemplateColorScheme> => {
      if (targetTemplateInfo.dterUrl) {
        const instructionsUrl = urlHelper.createInstructionsUrl(targetTemplateInfo.dterUrl, [1]);
        const previewUrl = urlHelper.createMcpRenderingUrl(instructionsUrl);
        const colorData = await GetColorPalete(previewUrl);
        return colorData;
      }

      return { dominantColor: '', titleColor: '', bodyColor: '' };
    };

    createColorData().then(colorData => {
      setPreviewColorScheme(colorData);
    });
  }, [targetTemplateInfo]);

  const updateStorefrontInstructions = async () => {
    const instructions = await buildStorefrontInstructions(targetTemplateInfo.dssUrl, textAssets, logoAssets, textTransformations, colorTransformations);
    const encoded = await buildDtitStorefront(instructions);
    const url = dtrApi.getRealizationStorefrontUrl(encoded, { culture: selectedCulture.fullCultureCode, environment: 'DEV' });  
    setDtrUrl(url);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ maxWidth: '600px', marginRight: '40px' }}>
        <TargetTemplateToken targetTemplateInfo={targetTemplateInfo} setTargetTemplateInfo={setTargetTemplateInfo} />

        <TextAssets onAssetsChanged={setTextAssets} textAssets={textAssets} />

        <LogoUrl onAssetsChanged={setLogoUrl} />

        <SimpleTransformationEdit<TextTransformations> header="Text" onValueChanged={setTextTransformations} value={textTransformations} />

        <ColorTransformationEdit header="Color" onValueChanged={setColorTransformations} value={colorTransformations} colorScheme={previewColorScheme} />
      </div>

      <div style={{ position: 'relative' }}>
        <div style={{ position: 'fixed', top: '68px', display: 'flex' }}>
          {targetTemplateInfo.dterUrl && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <div>
                  <h4 className="section-header underline">
                    <span className="highlight">Source</span> template
                  </h4>
                </div>
                <DocumentPreview documentUrl={targetTemplateInfo.dterUrl} panelToShow={1} />
              </div> 
              <button type="button" onClick={() => updateStorefrontInstructions()}>
                Realize
              </button>
            </div>
          )}
          {dtrUrl && (
            <div style={{ marginLeft: '30px' }}>
              <h4 className="section-header underline">
                <span className="highlight">Realized</span> document
              </h4>
              <DocumentPreview documentUrl={dtrUrl} panelToShow={1} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreFront;

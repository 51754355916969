import React, { useState } from 'react';
import PreviewUrlPage from '../components/transformation-realization/PreviewUrlPage';
import { Tab, Tabs } from '../components/common';
import PreviewInstructions from '../components/transformation-realization/PreviewInstructions';
import DocumentPreviewComparer from '../components/transformation-realization/DocumentPreviewComparer';
import CultureCodesComponent from '../components/culture-codes';
import StoreFront from '../components/transformation-realization/Storefront/StoreFront';
import { CultureCode } from '../redux/models/CultureCode';

const RealizationPage = () => {
  const [currentCultureCode, setCurrentCultureCode] = useState<CultureCode>({
    countryName: 'English (United States)',
    shortCultureCode: 'en-US',
    fullCultureCode: 'en-US'
  });

  return (
    <main>
      <div style={{ position: 'absolute', right: '20px', top: '9px' }}>
        <CultureCodesComponent selectedCultureCode={currentCultureCode} setSelectedCultureCode={setCurrentCultureCode} />
      </div>
      <Tabs defaultActiveTab={'Preview Instructions'}>
        <Tab title={'Preview Instructions'}>
          <PreviewInstructions selectedCulture={currentCultureCode} setSelectedCultureCode={setCurrentCultureCode} />
        </Tab>
        <Tab title={'Preview URL Analysis'}>
          <PreviewUrlPage cultureCode={currentCultureCode} />
        </Tab>
        <Tab title={'Feature testing'}>
          <DocumentPreviewComparer selectedCulture={currentCultureCode} />
        </Tab>
        <Tab title={'Vistaprint 2.9'}>
          <StoreFront selectedCulture={currentCultureCode} />
        </Tab>
      </Tabs>
    </main>
  );
};

export default RealizationPage;

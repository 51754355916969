import { EnvironmentType } from './model/dtr-environment-type';
import { generateGuid } from '../../tools/guid';

export interface DtrContext {
  culture?: string;
  authToken?: string;
  environment?: EnvironmentType;
  requester?: string;
  correlationId?: string;
  fillEmptyPlaceholders?: boolean;
}

export class DtrContextImplementation implements DtrContext {
  culture: string;
  authToken: string;
  environment: EnvironmentType;
  requester: string;
  correlationId: string;
  fillEmptyPlaceholders: boolean;

  constructor(context: DtrContext) {
    this.culture = context.culture || 'en-US';
    this.authToken = context.authToken || '';
    this.environment = context.environment || 'PROD';
    this.requester = context.requester || 'UI Catalog Tool';
    this.correlationId = context.correlationId || generateGuid();
    this.fillEmptyPlaceholders = context.fillEmptyPlaceholders || false;
  }
}

import React, { ChangeEvent, CSSProperties } from 'react';

interface Props {
  name: string;
  leftLabel?: string;
  rightLabel?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  style?: CSSProperties;
}

const SlidingSwitch = ({ name, leftLabel, rightLabel, onChange, checked, style }: Props) => (
  <div className="form-group switch" style={style}>
    {leftLabel && <span className="title left">{leftLabel}</span>}
    <label htmlFor={name} className="switch">
      <input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />
      <span className="slider round" />
    </label>
    {rightLabel && <span className="title left">{rightLabel}</span>}
  </div>
);

export default SlidingSwitch;

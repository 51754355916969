import React from 'react';
import AceEditor from 'react-ace';

interface Props {
  onValueChanged: (value: string) => void;
  value: string;
  placeholder?: string;
  debounceChangePeriod?: number;
  height?: number;
}

const JsonEditor = ({ value, onValueChanged, placeholder, debounceChangePeriod, height }: Props) => {
  const style = { width: `550px`, height: `${height || 300}px`, border: '1px solid lightgray' };
  return (
    <div style={{ width: 'fit-content', position: 'relative' }}>
      <AceEditor
        debounceChangePeriod={debounceChangePeriod}
        mode={'json'}
        theme="textmate"
        style={style}
        onChange={onValueChanged}
        //onInput={onValueChanged}
        wrapEnabled={true}
        placeholder={placeholder}
        value={value}
        editorProps={{ $blockScrolling: true }}
        setOptions={{ indentedSoftWrap: false }}
        showPrintMargin={false}
        highlightActiveLine={false}

      />
    </div>
  );
};

export default JsonEditor;

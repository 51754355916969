import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Tabs.module.scss';

const Tabs = props => {
  const { children, onCloseClick, defaultActiveTab } = props;

  // set first Tab as default
  const [activeTab, setActiveTab] = useState(defaultActiveTab || children[0].props.title);

  const onClickTabItem = title => {
    setActiveTab(title);
  };

  return (
    <>
      <div className={classes.tabs}>
        {children.map(child => {
          const { title } = child.props;

          const tabClassName = activeTab === title ? `${classes.tab} ${classes.active}` : classes.tab;

          return (
            <div key={title} className={tabClassName} onClick={() => onClickTabItem(title)}>
              {title}
            </div>
          );
        })}
        {onCloseClick && (
          <div className={classNames(classes.tab, classes.close)} onClick={() => onCloseClick()}>
            <i className="fas fa-times" />
          </div>
        )}
      </div>
      {children.map(child => {
        if (child.props.title !== activeTab) return null;
        return (
          <div key={`tab-content_${child.title}`} className={classes.tabContent}>
            {child.props.children}
          </div>
        );
      })}
    </>
  );
};

Tabs.propTypes = {
  defaultActiveTab: PropTypes.string,
  onCloseClick: PropTypes.func,
  children: PropTypes.instanceOf(Array).isRequired
};

export default Tabs;

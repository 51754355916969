import React from 'react';
import { ReadonlyText } from '../common';
import * as urlHelper from '../../api/url-helper';

interface Props {
  documentUrl: string;
}

const RenderDocument3DLink = ({ documentUrl }: Props) => {
  if (!documentUrl || documentUrl === '') {
    return null;
  }

  const instructionsUrl = urlHelper.createInstructionsUrl(documentUrl, []);
  const renderingUrl = urlHelper.create3DRenderingUrl(instructionsUrl);

  return (
    <a href={renderingUrl} target="_blank" rel="noopener noreferrer">
      <form>
        <ReadonlyText value={renderingUrl} />
      </form>
    </a>
  );
};

export default RenderDocument3DLink;

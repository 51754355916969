import React, { useState } from 'react';
import { SlidingSwitch } from '../common';
import * as api from '../../api/DesignTransformationRealizationService/design-transformation-realization-api';
import AceEditor from 'react-ace';
import { EnvironmentType } from '../../api/DesignTransformationRealizationService/model/dtr-environment-type';
import { TransformationInstructions } from './TransformationInstructions';

require('brace/mode/json');
require('brace/theme/textmate');

interface Props {
  getToken: (tokenGetter: () => Promise<TransformationInstructions>) => void;
  dtrEnvironment: EnvironmentType;
}

const DtiTokenEditor = ({ getToken, dtrEnvironment }: Props) => {
  const defaultInstructions: TransformationInstructions = {
    decodedValue: '',
    encodedValue: ''
  };

  const [instructions, setInstructions] = useState(defaultInstructions);
  const [isEncodedSwitch, setIsEncodedSwitch] = useState(true);

  const setNewInstructions = (isEncoded: boolean, newInstructions: string) => {
    let { decodedValue, encodedValue } = instructions;

    if (isEncoded) {
      encodedValue = newInstructions;
    } else {
      decodedValue = newInstructions;
    }

    setInstructions({ decodedValue, encodedValue });
  };

  const encodeDecodeInstructions = async (encode: boolean = false, isChanged: boolean = false) => {
    let { decodedValue, encodedValue } = instructions;

    try {
      
      if (encode === true) 
      {
        if(isChanged && decodedValue){
          encodedValue = await api.createToken(decodedValue, { environment: dtrEnvironment });
        }       
      } 
      
      else
      {
        if(isChanged){
          if(encodedValue){
            decodedValue = await api.parseToken(encodedValue, { authToken: undefined, environment: dtrEnvironment });
          }
        }

        else {
          encodedValue = await api.createToken(decodedValue, { environment: dtrEnvironment });
        }
      }


    } catch (e) {
      alert(e.message);
    }

    return { decodedValue, encodedValue };
  };

  const beautifyDecodedInstructions = (decodedValue: string | {}): string => {
    try {
      const jsonParsed = typeof decodedValue === 'string' ? JSON.parse(decodedValue) : decodedValue;

      return JSON.stringify(jsonParsed, null, 2);
    } catch {
      return decodedValue as string;
    }
  };

  const onEncodeSwitchChange = async (isEncoded: boolean) => {
    const newInstructions = (await encodeDecodeInstructions(isEncoded, true)) as TransformationInstructions;

    if (!isEncoded && newInstructions.decodedValue) {
      newInstructions.decodedValue = beautifyDecodedInstructions(newInstructions.decodedValue);
    }

    setIsEncodedSwitch(isEncoded);
    setInstructions(newInstructions);
  };

  getToken(() => encodeDecodeInstructions(isEncodedSwitch));

  return (
    <div style={{ width: 'fit-content', position: 'relative' }}>
      <AceEditor
        mode={isEncodedSwitch ? 'text' : 'json'}
        theme="textmate"
        style={{ width: '800px', height: '300px', border: '1px solid lightgray' }}
        onChange={value => setNewInstructions(isEncodedSwitch, value)}
        name="instructions-editor"
        wrapEnabled={true}
        placeholder={isEncodedSwitch ? '// design transformation token' : '// plain transformation instructions'}
        value={isEncodedSwitch ? instructions.encodedValue : instructions.decodedValue}
        editorProps={{ $blockScrolling: true }}
        setOptions={{ indentedSoftWrap: false }}
        showPrintMargin={false}
        highlightActiveLine={false}
      />
      <SlidingSwitch
        style={{ position: 'absolute', right: '10px', bottom: '14px', backgroundColor: 'white', paddingLeft: '8px', zIndex: 1000 }}
        name="instructions-switch"
        leftLabel="Encoded token"
        checked={isEncodedSwitch}
        onChange={event => {
          onEncodeSwitchChange(event.target.checked);
        }}
      />
    </div>
  );
};

export default DtiTokenEditor;

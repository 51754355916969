import React, { ChangeEventHandler, CSSProperties } from 'react';

interface Props {
  name?: string;
  label?: string;
  inline?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value: string | string[] | number | undefined;
  error?: string;
  style?: CSSProperties;
  readonly?: boolean;
  type?: string;
}

const TextInput = ({ name, label, onChange, placeholder, inline, value, error, style, readonly, type }: Props) => {
  let wrapperClass = 'form-group';
  if (inline) {
    wrapperClass += ' inline';
  }
  if (error && error.length > 0) {
    wrapperClass += ' has-error';
  }

  const inputType = type || 'text';

  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        <input
          type={inputType}
          name={name}
          id={name}
          className="form-control"
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
          style={style}
          readOnly={readonly}
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

export default TextInput;

import Vibrant from 'node-vibrant';
import { Swatch } from 'node-vibrant/lib/color';
import colorconvert from 'color-convert';

export interface TemplateColorScheme {
  hsl?: number[];
  dominantColor: string;
  titleColor: string;
  bodyColor: string;
}

const emptyPallet: TemplateColorScheme = {
  bodyColor: '',
  dominantColor: '',
  titleColor: ''
};

const toReturnType = (swatch: Swatch): TemplateColorScheme => {
  return {
    hsl: swatch.hsl,
    dominantColor: swatch.hex,
    bodyColor: swatch.getBodyTextColor(),
    titleColor: swatch.getTitleTextColor()
  };
};

export async function GetColorPalete(previewUrl: string): Promise<TemplateColorScheme> {
  const palette = await Vibrant.from(previewUrl).getPalette();
  if (!palette) return emptyPallet;
  const vibrants = [palette.DarkMuted, palette.DarkVibrant, palette.LightMuted, palette.LightVibrant, palette.Muted, palette.Vibrant];
  const max = vibrants.reduce(function(p, v) {
    return p && v ? (p.getPopulation() > v.getPopulation() ? p : v) : p;
  });

  return max ? toReturnType(max) : emptyPallet;
}

export const hexTpRgb = (hex: string): string => {
  const rgb = colorconvert.hex.rgb(hex);
  return rgb ? `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})` : '';
};

const getYiq = (rgb: number[]): number => {
  return (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
};

export const getBodyTextColor = (rgb: number[]): string => {
  const yiq = getYiq(rgb);
  return yiq < 150 ? '#fff' : '#000';
};

export const getTitleTextColor = (rgb: number[]): string => {
  const yiq = getYiq(rgb);
  return yiq < 200 ? '#fff' : '#000';
};

import axios from 'axios';
import * as config from '../../config';
import { ApiError } from './model/api-error';
import { DesignTransformationInstructions } from './model/design-transformation-instructions';
import { RealizationServiceResult } from './realization-service-result';
import { CreateParseApiError } from './model/create-parse-api-error';
import { DtrContext, DtrContextImplementation } from './dtr-context';
import { EnvironmentType } from './model/dtr-environment-type';

const devHost = config.devRealizationServiceHost;
const prodHost = config.prodRealizationServiceHost;

const parseRealizeServiceError = (error: ApiError): string => {
  if (error.response && [401, 403].includes(error.response.status)) {
    return `Invalid Auth Token. Design Transformation Service responded with ${error.response.status}.`;
  }

  return 'Design Transformation Service failed.';
};

const headers: { [s: string]: string } = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

function getEnvironmentUrl(environment: EnvironmentType | undefined) {
  return environment === 'DEV' ? devHost : prodHost;
}

export function getRealizationUrl(encodedDti: string, requestContext: DtrContext): string {
  const context = new DtrContextImplementation(requestContext);
  const host = getEnvironmentUrl(context.environment);
  const url = `${host}/api/v3/realizeToken?token=${encodedDti}&requester=${encodeURIComponent(context.requester)}&culture=${encodeURIComponent(
    context.culture
  )}&fillDefaultText=${encodeURIComponent(context.fillEmptyPlaceholders)}`;

  return url;
}

export function getRealizationStorefrontUrl(encodedDti: string, requestContext: DtrContext): string {
  const context = new DtrContextImplementation(requestContext);
  const host = getEnvironmentUrl(context.environment);
  const url = `${host}/api/v3/realizeStorefront?token=${encodedDti}&requester=${encodeURIComponent(context.requester)}&culture=${encodeURIComponent(
    context.culture
  )}`;

  return url;
}

export async function realize(encodedDti: string, requestContext: DtrContext): Promise<RealizationServiceResult> {
  const url = getRealizationUrl(encodedDti, requestContext);

  try {
    const result = await axios.get(url, { headers });

    return {
      realizedDocumentUrl: url,
      document: result.data
    };
  } catch (error) {
    throw new Error(parseRealizeServiceError(error));
  }
}

export async function loadSourceDocumentPayload(dti: DesignTransformationInstructions): Promise<{}> {
  const sourceDocumentUrl = dti.source.document && (dti.source.document.value as string);

  if (!sourceDocumentUrl) {
    throw new Error('Could not find source Document in Design Transformation Instructions. Expecting: source.document.value');
  }

  try {
    const result = await axios.get(sourceDocumentUrl, { headers, params: { credentials: 'same-origin' } });
    return result.data;
  } catch (error) {
    console.log(`Could not load source Document content (${sourceDocumentUrl}).`);
    console.log(error);
    return {};
  }
}

const getErrorMessageFromCreateParse = (error: CreateParseApiError, actionName: string): string => {
  if (error.status && [401, 403].includes(error.status)) {
    return `Invalid Auth Token for ${actionName} action. Design Transformation Service responded with ${error.status}.`;
  }

  if (error.data && error.data.detail) {
    return `Cannot ${actionName}. ${error.data.detail}`;
  }

  return `Cannot ${actionName} token. Invalid input data.`;
};

export const createToken = async (dti: string, requestContext?: DtrContext) => {
  const context = new DtrContextImplementation(requestContext || {});
  const host = getEnvironmentUrl(context.environment);
  const url = `${host}/api/v1/dti/createToken`;

  const body = dti;

  try {
    const result = await axios.post(url, body, { headers });

    return result.data.token;
  } catch (error) {
    throw new Error(getErrorMessageFromCreateParse(error.response as CreateParseApiError, 'create'));
  }
};

export const createStorefrontToken = async (dti: string, requestContext?: DtrContext): Promise<string> => {
  const context = new DtrContextImplementation(requestContext || {});
  const host = getEnvironmentUrl(context.environment);
  const url = `${host}/api/v1/dti/createStorefrontToken`;

  const body = dti;

  try {
    const result = await axios.post(url, body, { headers });

    return result.data.token;
  } catch (error) {
    throw new Error(getErrorMessageFromCreateParse(error.response as CreateParseApiError, 'create'));
  }
};

export const parseToken = async (encodedDti: string, requestContext: DtrContext) => {
  const context = new DtrContextImplementation(requestContext);
  const host = getEnvironmentUrl(context.environment);
  const url = `${host}/api/v1/dti/parseToken?token=${encodedDti}`;

  headers.Authorization = `Bearer ${context.authToken}`;

  try {
    const result = await axios.get(url, { headers });

    const instructions = result.data;

    return instructions;
  } catch (error) {
    throw new Error(getErrorMessageFromCreateParse(error.response as CreateParseApiError, 'parse'));
  }
};

import React from 'react';
import DocumentPreview from './DocumentPreview';
import McpRenderDocumentLink from './McpRenderDocumentLink';
import RenderDocument3DLink from './RenderDocument3DLink';

interface Props {
  documentUrl: string;
}

const DocumentImagePreviews = ({ documentUrl }: Props) => {
  if (!documentUrl || documentUrl === '') {
    return null;
  }

  return (
    <>
      <h4>3D rendering</h4>
      <p>
        <RenderDocument3DLink documentUrl={documentUrl} />
      </p>
      <h4>MCP rendering</h4>
      <p>
        <McpRenderDocumentLink documentUrl={documentUrl} />
      </p>

      <DocumentPreview documentUrl={documentUrl} panelToShow={1} />
    </>
  );
};

export default DocumentImagePreviews;

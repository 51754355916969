import React, { useEffect, useState } from 'react';
import JsonEditor from '../../common/JsonEditor';

interface Props<T> {
  header: string;
  onValueChanged: (value: T) => void;
  value: T;
}

function SimpleTransformationEdit<T>({ header, onValueChanged, value }: Props<T>) {
  const [instructions, setInstructions] = useState(JSON.stringify(value, undefined, 2));

  useEffect(() => {
    const updateValue = (valueString: string) => {
      console.log('matej1');
      const valueStrigified = JSON.stringify(value, undefined, 2)
      console.log(valueString);
      console.log(valueStrigified);
      if (valueString !== valueStrigified) {
        try {
          console.log('matej2');
          const newValue = JSON.parse(valueString);
          console.log('matej3');
          onValueChanged(newValue);
        } catch (error) {
          console.error(error);
        }
      }
    };

    updateValue(instructions);
    // eslint-disable-next-line
  }, [instructions]);

  return (
    <>
      <h4 className="section-header underline">
        <span className="highlight">{header}</span> Transformation
      </h4>

      <JsonEditor height={230} value={instructions} onValueChanged={value => setInstructions(value)} placeholder={'// instructions'} />
    </>
  );
}

export default SimpleTransformationEdit;

import * as config from '../config';

export const createInstructionsUrl = (documentUrl: string, slots: number[]): string => {
  const encodedDocumentUrl = encodeURIComponent(documentUrl);

  const slotsPrefix = slots.length === 0 ? '' : `&surfaceOrdinals=${encodeURIComponent(slots.join(','))}`;
  const udsUrl = `${config.udsInstructionsPath}?documentUri=${encodedDocumentUrl}${slotsPrefix}`;

  return udsUrl;
};

export const createMcpRenderingUrl = (instructionsUrl: string): string => {
  const encodedDocumentUrl = encodeURIComponent(instructionsUrl);

  const udsUrl = config.mcpRenderingHostPath + '&instructions_uri=' + encodedDocumentUrl + '&width=300';

  return udsUrl;
};

export const create3DRenderingUrl = (instructionsUrl: string): string => {
  const encodedDocumentUrl = encodeURIComponent(instructionsUrl);

  const vortex3DUrl = config.vortex3DRenderingHostPath + '?renderingInstructions=' + encodedDocumentUrl;

  return vortex3DUrl;
};

import React, { ChangeEventHandler, CSSProperties } from 'react';

interface Props {
  name?: string;
  label?: string;
  inline?: boolean;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  defaultOption?: string;
  value: string | number;
  error?: string;
  options: {
    text: string;
    value: string;
  }[];
  style?: CSSProperties;
}

const SelectInput = ({ name, label, onChange, defaultOption, value, inline, error, options, style }: Props) => {
  let wrapperClass = 'form-group select';
  if (inline) {
    wrapperClass += ' inline';
  }

  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select id={name} name={name} value={value} onChange={onChange} className="form-control" style={style}>
          {defaultOption && <option value="">{defaultOption}</option>}
          {options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

export default SelectInput;

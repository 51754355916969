import React, { CSSProperties } from 'react';
import copyToClipboard from '../../tools/clipboard';
import './ReadonlyText.module.scss';

interface Props {
  value: string | string[] | number | undefined;
  style?: CSSProperties;
}

const ReadonlyText = ({ value, style }: Props) => {
  const toClipboard = (value: string) => () => {
    copyToClipboard(value);
  };

  const inputValue = value as string;

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div className={`form-group inline`} style={{ width: '80%' }}>
        <div className="field">
          <input type="text" className={`form-control form-control-readonly`} value={inputValue} style={style} readOnly={true} />
        </div>
      </div>

      <button className="link" type="button" onClick={toClipboard(inputValue)}>
        Copy
      </button>
    </div>
  );
};

export default ReadonlyText;

import React from 'react';
import { ReadonlyText } from '../common';
import * as urlHelper from '../../api/url-helper';

interface Props {
  documentUrl: string;
}

const McpRenderDocumentLink = ({ documentUrl }: Props) => {
  if (!documentUrl || documentUrl === '') {
    return null;
  }

  const instructionsUrl = urlHelper.createInstructionsUrl(documentUrl, []);
  const mcpRenderingUrl = urlHelper.createMcpRenderingUrl(instructionsUrl);

  return (
    <a href={mcpRenderingUrl} target="_blank" rel="noopener noreferrer">
      <form>
        <ReadonlyText value={mcpRenderingUrl} />
      </form>
    </a>
  );
};

export default McpRenderDocumentLink;

import React, { useState } from 'react';
import { TextInput } from '../../common';

interface Props {
  onAssetsChanged: (logoUrl: string) => void;
}

const LogoUrl = ({ onAssetsChanged }: Props) => {
  const predefinedLogos = [
    {
      name: 'Vistaprint',
      logoUrl: 'https://uploads.documents.cimpress.io/v1/uploads/03d88767-3c7d-4ddf-9347-b1ac14e8822c~100/print?tenant=designtechprod'
    },
    {
      name: 'Cimpress',
      logoUrl: 'https://uploads.documents.cimpress.io/v1/uploads/f5ec59c1-c32c-40af-9aa6-95013f9717dc~100/print?tenant=designtechprod'
    },
    {
      name: 'Vistaprint Red',
      logoUrl: 'https://uploads.documents.cimpress.io/v1/uploads/aaafec26-a7ba-49f9-b237-f448ed0b2b8e~100/print?tenant=designtechprod'
    },
    {
      name: 'National Pen',
      logoUrl: 'https://uploads.documents.cimpress.io/v1/uploads/2e52f116-055f-4fb8-b924-2a39527789ce~100/original?tenant=designtechprod'
    },
    {
      name: 'Build A Sign',
      logoUrl: 'https://uploads.documents.cimpress.io/v1/uploads/7906e726-fc0c-4428-8f65-16672f452a7d~100/original?tenant=designtechprod'
    }
  ];

  const [logoUrl, setLogoUrl] = useState('');

  const updateAssets = (logUrl: string) => {
    setLogoUrl(logUrl);
    onAssetsChanged(logUrl);
  };

  return (
    <form style={{ flexDirection: 'column', maxWidth: '600px' }}>
      <h4 className="section-header underline">
        <span className="highlight">Logo</span>
      </h4>
      <TextInput
        inline
        style={{ marginRight: '8px', marginBottom: '8px' }}
        value={logoUrl}
        onChange={e => {
          updateAssets(e.target.value);
        }}
        placeholder="https://"
      />

      <div style={{ display: 'flex' }}>
        {predefinedLogos.map(example => (
          <button key={example.name} type="button" className="alt" onClick={() => updateAssets(example.logoUrl)}>
            {example.name}
          </button>
        ))}
      </div>
    </form>
  );
};

export default LogoUrl;

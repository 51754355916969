import React from 'react';
import { Tabs, Tab } from '../common';
import DocumentViewer from './DocumentViewer';
import { RealizationServiceResult } from '../../api/DesignTransformationRealizationService/realization-service-result';
import DocumentImagePreviews from './DocumentImagePreviews';

interface Props {
  realizationResult: RealizationServiceResult;
}

const RealizationOutput = ({ realizationResult }: Props) => {
  if (!realizationResult || (!realizationResult.realizedDocumentUrl && !realizationResult.document)) {
    return null;
  }

  return (
    <div style={{ marginTop: '1em' }}>
      <Tabs>
        <Tab title={'Image Preview'}>
          <DocumentImagePreviews documentUrl={realizationResult.realizedDocumentUrl} />
        </Tab>
        <Tab title={'Output Document'}>
          <DocumentViewer document={realizationResult.document} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default RealizationOutput;
